
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DateTimePicker extends Vue {
  menu = false;
  date = '';
  time = '';

  get dateTime() {
    if (this.date && this.time) {
      return `${this.date} ${this.time}`;
    }
    return '';
  }

  set dateTime(val) {
    // parse the date and time from the value
    if (val) {
      const [date, time] = val.split(' ');
      this.date = date;
      this.time = time;
    }
  }

  save() {
    this.menu = false;
    const selectedDateTime = new Date(`${this.date} ${this.time}`);
    const timestamp = selectedDateTime.getTime(); // Unix timestamp in milliseconds
    this.$emit('input', timestamp);
  }
}
