
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SupportedProviders } from '@/interfaces';
import {
  SynchronizeAllConceptsPayload,
  SynchronizeDataPayload,
  SynchronizeLocationPayload,
} from '@/store/interfaces';
import DateTimePicker from '@/components/DateTimePicker.vue';

const appStore = namespace('app');
const conceptsStore = namespace('concepts');

@Component({ components: { DateTimePicker } })
export default class SynchronizeBtn extends Vue {
  @Prop({ required: true }) readonly type!: string;
  @Prop({ required: true }) readonly itemId!: number;

  @appStore.State('providersList')
  readonly providers!: SupportedProviders[];

  @appStore.Getter
  readonly isRebel!: boolean;

  @conceptsStore.Action
  synchronizeAllConcepts!: (data: SynchronizeAllConceptsPayload) => Promise<void>;

  @conceptsStore.Action
  synchronizeLocation!: (data: SynchronizeLocationPayload) => Promise<void>;

  @conceptsStore.Action
  synchronizeData!: (data: SynchronizeDataPayload) => Promise<void>;

  menu = false;
  dialog = false;
  scheduledTime = String(Date.now());
  selectedProvider: SupportedProviders | null = null;

  openModal(provider: SupportedProviders | null) {
    this.selectedProvider = provider;
    this.dialog = true;
  }

  confirmSync() {
    this.dialog = false;
    this.synchronize(this.selectedProvider);
  }

  synchronize(provider: SupportedProviders | null = null): void {
    const syncData = {
      provider,
      scheduledTime: this.scheduledTime,
    };

    switch (this.type) {
      case 'restaurant':
        this.synchronizeAllConcepts({ restaurantId: this.itemId, ...syncData });
        break;

      case 'location':
        this.synchronizeLocation({ locationId: this.itemId, ...syncData });
        break;

      case 'concept':
        this.synchronizeData({ conceptId: this.itemId, ...syncData });
        break;
    }
  }
}
